<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户ID" prop="userNo">
                <a-input v-model="queryParam.userNo" placeholder="请输入用户ID" allow-clear/>
              </a-form-item>
            </a-col>
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item label="充值菜单id" prop="rechargeMenuId">-->
<!--                  <a-input v-model="queryParam.rechargeMenuId" placeholder="请输入充值菜单id" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item label="支付类型" prop="paymentType">
                  <a-select placeholder="请选择支付类型" v-model="queryParam.paymentType" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.pay_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="第三方订单号" prop="outTradeNo">
                  <a-input v-model="queryParam.outTradeNo" placeholder="请输入第三方订单号" allow-clear/>
                </a-form-item>
              </a-col>
            <a-col :md="10" :sm="24">
              <a-form-item label="创建时间"  >
                <a-date-picker v-model="queryParam.startTime"    placeholder="请选择创建时间" @change="onChangeS" allow-clear/> 至
                <a-date-picker v-model="queryParam.endTime"  placeholder="请选择创建时间" @change="onChangeE" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'left', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:user:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:user:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:user:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:user:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :paymentTypeOptions="dict.type.pay_type"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="paymentType" slot-scope="text, record">
          <dict-tag :options="dict.type['pay_type']" :value="record.paymentType"/>
        </span>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:user:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:user:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['biz:user:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser } from '@/api/biz/userRecharge'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['pay_type'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        userId: null,
        rechargeMenuId: null,
        paymentType: null,
        outTradeNo: null,
        orderStatus: null,
        createTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        // {
        //   title: '充值类型 0-普通  1-首充',
        //   dataIndex: 'type',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '用户ID',
          dataIndex: 'userNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '充值菜单id',
        //   dataIndex: 'rechargeMenuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '支付类型',
          dataIndex: 'paymentType',
          scopedSlots: { customRender: 'paymentType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '金额',
          dataIndex: 'rechargeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '钻石数',
          dataIndex: 'diamondNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '第三方订单号',
          dataIndex: 'outTradeNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (text === 1) {
              return '待付款'
            } else if (text === 2) {
              return '已完成'
            } else if (text === 3) {
              return '已关闭'
            }
          }
        },
        {
          title: '付款时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //时间选择
    onChangeS (value, dateString) {
      this.queryParam.startTime = dateString;
      console.log('Formatted Selected Time: ', dateString)
    },
    onChangeE (value, dateString) {
      this.queryParam.endTime = dateString;
      console.log('Formatted Selected Time: ', dateString)
    },


    /** 查询用户充值记录列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        userId: undefined,
        rechargeMenuId: undefined,
        paymentType: undefined,
        outTradeNo: undefined,
        orderStatus: undefined,
        createTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/userRecharge/export', {
            ...that.queryParam
          }, `用户充值记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
