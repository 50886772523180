<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="订单编号" prop="orderNo" >
        <a-input v-model="form.orderNo" placeholder="请输入订单编号" />
      </a-form-model-item>
      <a-form-model-item label="充值类型 0-普通  1-首充" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="充值菜单id" prop="rechargeMenuId" >
        <a-input v-model="form.rechargeMenuId" placeholder="请输入充值菜单id" />
      </a-form-model-item>
      <a-form-model-item label="充值金额" prop="rechargeMoney" >
        <a-input v-model="form.rechargeMoney" placeholder="请输入充值金额" />
      </a-form-model-item>
      <a-form-model-item label="支付类型 2-微信 3-支付宝 4-苹果内购" prop="paymentType" >
        <a-select placeholder="请选择支付类型 2-微信 3-支付宝 4-苹果内购" v-model="form.paymentType">
          <a-select-option v-for="(d, index) in paymentTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="付款编号" prop="paymentNo" >
        <a-input v-model="form.paymentNo" placeholder="请输入付款编号" />
      </a-form-model-item>
      <a-form-model-item label="第三方订单号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入第三方订单号" />
      </a-form-model-item>
      <a-form-model-item label="订单状态1待付款 2已完成 3已关闭" prop="orderStatus" >
      </a-form-model-item>
      <a-form-model-item label="轮寻次数" prop="selNum" >
        <a-input v-model="form.selNum" placeholder="请输入轮寻次数" />
      </a-form-model-item>
      <a-form-model-item label="订单付款时间" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="订单完成时间" prop="finishTime" >
        <a-date-picker style="width: 100%" v-model="form.finishTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/biz/userRecharge'

export default {
  name: 'CreateForm',
  props: {
    paymentTypeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        orderNo: null,

        type: null,

        userId: null,

        rechargeMenuId: null,

        rechargeMoney: null,

        paymentType: null,

        paymentNo: null,

        outTradeNo: null,

        orderStatus: 0,

        selNum: null,

        payTime: null,

        finishTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        paymentType: [
          { required: true, message: '支付类型 2-微信 3-支付宝 4-苹果内购不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        orderNo: null,
        type: null,
        userId: null,
        rechargeMenuId: null,
        rechargeMoney: null,
        paymentType: null,
        paymentNo: null,
        outTradeNo: null,
        orderStatus: 0,
        selNum: null,
        payTime: null,
        finishTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
