import request from '@/utils/request'


// 查询用户充值记录列表
export function listUser(query) {
  return request({
    url: '/biz/userRecharge/list',
    method: 'get',
    params: query
  })
}

// 查询用户充值记录分页
export function pageUser(query) {
  return request({
    url: '/biz/userRecharge/getUserRechargePage',
    method: 'get',
    params: query
  })
}

// 查询用户充值记录详细
export function getUser(data) {
  return request({
    url: '/biz/userRecharge/detail',
    method: 'get',
    params: data
  })
}

// 新增用户充值记录
export function addUser(data) {
  return request({
    url: '/biz/userRecharge/add',
    method: 'post',
    data: data
  })
}

// 修改用户充值记录
export function updateUser(data) {
  return request({
    url: '/biz/userRecharge/edit',
    method: 'post',
    data: data
  })
}

// 删除用户充值记录
export function delUser(data) {
  return request({
    url: '/biz/userRecharge/delete',
    method: 'post',
    data: data
  })
}
